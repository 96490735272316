import React, { Component } from 'react';
import './style.scss';
import Icon from '../Icon/Icon';
import MobileLayout from './MobileLayout/MobileLayout';
import DesktopLayout from './DesktopLayout/DesktopLayout';
import LangToggle from '../../containers/OptionsHeader/LangToggle/LangToggle';
import Social from './Social/Social';

class Footer extends Component {

    state = {
        urlPrefix: '',
        lang: this.props.lang,
        isDesktop: this.props.isDesktop,
        currentYear: new Date()// for footer year
    }

    render() {
        
        return (
            <footer className="footer">
                <Social />
                {/* <p className="language-selector"><span><Icon type="flag" /><a className="ec" onClick={lang.switchTo.bind(lang, "en")}>En</a> | <a className="fc" onClick={lang.switchTo.bind(lang, "fr")}>Fr</a></span></p>
                 */}
                 <div className="language-selector"><span><Icon type="flag" /><LangToggle {...this.props} /></span></div>
                 {this.props.isDesktop === true ?
                    <DesktopLayout {...this.props} />
                    :
                    <MobileLayout {...this.props} />
                }
               

                {this.props.lang === 'en' ? 
                    <p className="copyright"><small>©{this.state.currentYear.getFullYear()} A&W Food Services of Canada Inc.
                    <br />Promotion run by A&W Food Services of Canada Inc., an authorized franchise of Pret A Manger (Europe) Limited.
                    <br />The trademarks that appear are the property of their respective trademark owners.</small>
                    </p>
                    :
                    <p className="copyright"><small>©{this.state.currentYear.getFullYear()} A&W Food Services of Canada Inc.
                    <br />Promotion organisée par Services Alimentaires A&W du Canada Inc., une franchise autorisée de Pret A Manger (Europe) Limited.
                    <br />
                    Les marques de commerce indiquées appartiennent à leur propriétaire respectif.
                    </small>
                    </p>
                }

            </footer>
        );
    }
}

export default Footer;

